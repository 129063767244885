import { cardRoutesDictionary } from './../../modules/app/card/routes/card-routes.dictionary';
import { creditsRoutesDictionary } from './../../modules/app/credits/routes/credits-routes.dictionary';
import { moduleRouteDictionaryFactory } from '@tundr/routing';
import { authRoutesDictionary } from '../../modules/auth/routes/auth-routes.dictionary.ts';
import { employeeRoutesDictionary } from '../../modules/app/employee/routes/employee-routes.dictionary.ts';
import { officeRoutesDictionary } from '../../modules/app/office/routes/office-routes.dictionary.ts';
import { hrRoutesDictionary } from '../../modules/app/hr/routes/hr-routes.dictionary.ts';

const rootRoutesDictionary = moduleRouteDictionaryFactory('root', '/', {
  notFound: '/:pathMatch(.*)*',
  app: '/app',
});

export const appRoutesDictionary = {
  ...rootRoutesDictionary,
  ...authRoutesDictionary,
  ...hrRoutesDictionary,
  ...creditsRoutesDictionary,
  ...employeeRoutesDictionary,
  ...officeRoutesDictionary,
  ...cardRoutesDictionary,
};
