import { employeeRoutesDictionary } from './employee-routes.dictionary';
import { RouteRecordRaw } from 'vue-router';

export const employeeRoutesConfig: RouteRecordRaw = {
  ...employeeRoutesDictionary.employees.root,
  redirect: { name: employeeRoutesDictionary.employees.list.name },
  children: [
    {
      ...employeeRoutesDictionary.employees.list,
      component: () => import('../pages/List/EmployeeListPage.vue'),
    },
    {
      ...employeeRoutesDictionary.employees.create,
      component: () => import('../pages/Create/CreateEmployeePage.vue'),
    },
    {
      ...employeeRoutesDictionary.employees.detail,
      component: () => import('../pages/EmployeeDetailTabsRoot.vue'),
      props: true,
    },
    {
      ...employeeRoutesDictionary.employees.editAnagraphic,
      component: () =>
        import('../pages/EditAnagraphicData/EditAnagraphicData.vue'),
      props: true,
    },
    {
      ...employeeRoutesDictionary.employees.editCompany,
      component: () => import('../pages/EditCompanyData/EditCompanyData.vue'),
      props: true,
    },
  ],
};
