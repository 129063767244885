<script lang="ts" setup>
  import TextInput from '../TextInput/TextInput.vue';
  import { computed, ref } from 'vue';
  import SvgImage from '../../SvgImage/SvgImage.vue';
  import passwordVisible from '@tundr/theme-assets/icons/visible-password-icon.svg';
  import passwordHidden from '@tundr/theme-assets/icons/hidden-password-icon.svg';
  import { disabledClass } from '@tundr/theme';
  import { TextInputProps } from '../TextInput';

  export type PasswordInputProps = Omit<TextInputProps, 'type'>;
  defineProps<PasswordInputProps>();

  const emit = defineEmits(['update:modelValue', 'blur']);

  const showPassword = ref(false);
  const inputType = computed(() => {
    return showPassword.value ? 'text' : 'password';
  });
  const passwordVisibleImage = computed(() => {
    return inputType.value == 'text' ? passwordVisible : passwordHidden;
  });
  const toggleInputType = () => {
    showPassword.value = !showPassword.value;
  };

  const handleChange = (val: string) => {
    emit('update:modelValue', val);
  };
  const handleBlur = (val: string) => {
    emit('blur', val);
  };
</script>

<template>
  <TextInput
    :width="width"
    :error="error"
    :label="label"
    :meta="meta"
    :disabled="disabled"
    :loading="loading"
    :modelValue="modelValue"
    :name="name"
    :placeholder="placeholder"
    :type="inputType"
    @update:model-value="handleChange"
    @blur="handleBlur"
  >
    <template #iconRight>
      <SvgImage
        :width="16"
        :class="(disabled || loading) && disabledClass"
        :clickable="true"
        :image="passwordVisibleImage"
        title=""
        @click="toggleInputType"
      ></SvgImage>
    </template>
  </TextInput>
</template>
