import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import type { Component as ComponentType } from 'vue';

export interface StepperComponent {
  name: string;
  component: ComponentType;
}

export interface StepperStoreProp {
  activeIndex: number;
  components: StepperComponent[];
}

interface StepperStore {
  steppers: Record<string, StepperStoreProp>;
}

export const useStepperStore = defineStore('stepper', () => {
  const steppers = useSessionStorage<StepperStore['steppers']>('steppers', {});

  const initializeStepper = (
    namespace: string,
    components: StepperComponent[],
    initialStep?: number,
  ) => {
    steppers.value[namespace] = {
      activeIndex: initialStep || steppers.value[namespace]?.activeIndex || 0,
      components,
    };
  };

  const nextStep = (namespace: string) => {
    const stepper = steppers.value[namespace];
    if (stepper?.activeIndex < stepper.components.length - 1) {
      stepper.activeIndex++;
    }
  };

  const previousStep = (namespace: string) => {
    const stepper = steppers.value[namespace];
    if (stepper?.activeIndex > 0) {
      stepper.activeIndex--;
    }
  };

  const goToStep = (namespace: string, stepName: string) => {
    const stepIndexByName = steppers.value[namespace].components.findIndex(
      (component) => component.name === stepName,
    );

    if (stepIndexByName >= 0) {
      steppers.value[namespace].activeIndex = stepIndexByName;
    }
  };

  const destroyStepper = (namespace: string) => {
    delete steppers.value[namespace];
  };

  return {
    steppers,
    initializeStepper,
    nextStep,
    previousStep,
    goToStep,
    destroyStepper,
  };
});
