<script setup lang="ts">
  import {
    closeSidePanelButtonClass,
    sidePanelBackgroundClass,
    sidePanelContainerClass,
  } from './SidePanel.css';
  import { useSidePanelStore } from '../../store/side-panel.store';
  import { computed, onMounted, onUnmounted } from 'vue';
  import { Icon, useFocusTrap } from '@tundr/ui-kit';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons.ts';

  const sidePanelStore = useSidePanelStore();

  const { trapRef } = useFocusTrap(
    sidePanelStore.sidePanelState.config?.firstSelectionOnLastItem,
  );

  function keydownListener(event: KeyboardEvent) {
    if (
      event.key === 'Escape' &&
      !sidePanelStore.sidePanelState.config?.blocking
    )
      sidePanelStore.closeSidePanel();
  }

  onMounted(() => {
    document.addEventListener('keydown', keydownListener);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', keydownListener);
  });

  const closeOnBackdrop = () => {
    if (!sidePanelStore.sidePanelState.config?.blocking)
      sidePanelStore.closeSidePanel();
  };

  const isVisible = computed<boolean>(() => {
    return !!sidePanelStore.sidePanelState.component;
  });
</script>

<template>
  <div>
    <Transition name="fade" appear>
      <div
        v-if="isVisible"
        :class="sidePanelBackgroundClass"
        @click.self="closeOnBackdrop"
      />
    </Transition>
    <Transition name="slide-in" appear>
      <div
        v-if="isVisible"
        ref="trapRef"
        :class="sidePanelContainerClass"
        aria-modal="true"
        tabindex="-1"
      >
        <Icon
          v-if="!sidePanelStore.sidePanelState.config?.blocking"
          clickable
          :class="closeSidePanelButtonClass"
          :iconName="ICONS.CLOSE"
          @click="sidePanelStore.closeSidePanel"
        />
        <component
          :is="sidePanelStore.sidePanelState.component"
          v-bind="sidePanelStore.sidePanelState.props"
        />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
  .slide-in-enter-active {
    transition: 0.3s ease-out all;
    transition-delay: 0.3s;
  }

  .slide-in-leave-active {
    transition: 0.3s ease-in all;
  }

  .slide-in-enter-from,
  .slide-in-leave-to {
    transform: translateX(100%);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active {
    transition: 0.3s ease all;
  }

  .fade-leave-active {
    transition: 0.2s ease all;
    transition-delay: 0.3s;
  }
</style>
