<template>
  <router-view v-slot="{ Component, route }">
    <transition
      :enter-active-class="route.meta.enterActiveClass"
      :leave-active-class="route.meta.leaveActiveClass"
      :appear="true"
      mode="out-in"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script lang="ts" setup>
  import {
    animated,
    slideInLeft,
    slideInRight,
    slideOutLeft,
    slideOutRight,
  } from '@tundr/theme';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  let previousRoute = '';

  router.afterEach((to, from) => {
    const isPageReloading = to.path === from.path;
    if (isPageReloading) return;

    const toDepth = new Set(to.path.split('/'));
    const fromDepth = new Set(from.path.split('/'));

    const isBack = toDepth < fromDepth || to.path === previousRoute;
    previousRoute = from.path;

    to.meta.enterActiveClass = isBack
      ? `${animated} ${slideInLeft}`
      : `${animated} ${slideInRight}`;
    to.meta.leaveActiveClass = isBack
      ? `${animated} ${slideOutRight}`
      : `${animated} ${slideOutLeft}`;
  });
</script>
