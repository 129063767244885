import {
  SimpleValidationRuleFunction,
  ValidationRuleDictionary,
} from '../types';
import {
  nameOrSurname,
  atLeastOneRequired,
  fieldsMustBeTheSame,
  internationalPhoneNumber,
  italianZipCode,
  password,
  taxId,
  taxIdOrUndefined,
  fieldIsRequiredIfAnother,
  date,
  time,
  dateIsNotTooOld,
  swanEmail,
  dateIsNotTooYoung,
} from './custom-rules';

export const customRules: ValidationRuleDictionary = {
  italianPostalCode: italianZipCode as SimpleValidationRuleFunction,
  nameOrSurname: nameOrSurname as SimpleValidationRuleFunction,
  taxId: taxId as SimpleValidationRuleFunction,
  internationalPhoneNumber:
    internationalPhoneNumber as SimpleValidationRuleFunction,
  taxIdOrUndefined: taxIdOrUndefined as SimpleValidationRuleFunction,
  atLeastOneRequired: atLeastOneRequired as SimpleValidationRuleFunction,
  fieldsMustBeTheSame: fieldsMustBeTheSame as SimpleValidationRuleFunction,
  fieldIsRequiredIfAnother:
    fieldIsRequiredIfAnother as SimpleValidationRuleFunction,
  password: password as SimpleValidationRuleFunction,
  date: date as SimpleValidationRuleFunction,
  time: time as SimpleValidationRuleFunction,
  dateIsNotTooOld: dateIsNotTooOld as SimpleValidationRuleFunction,
  dateIsNotTooYoung: dateIsNotTooYoung as SimpleValidationRuleFunction,
  swanEmail: swanEmail as SimpleValidationRuleFunction,
};
