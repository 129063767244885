<script setup lang="ts">
  import { ButtonVariant, themeVars } from '@tundr/theme';
  import { computed } from 'vue';
  import { Button, ButtonSizes } from '../../Button';
  import { ChipVariant } from '../../Chip';
  import Chip from '../../Chip/Chip.vue';
  import {
    cardActionsWrapperClass as cardActionsWrapperClass,
    cardContainerClass,
    cardContentClass,
    cardDataWrapperClass,
    cardImageWrapperClass,
    cardItemDataLabelClass,
    cardItemDataValueClass,
    cardItemDataWrapperClass,
    cardTitleClass,
    cardWrapperClass,
    statusChipClass,
    withSideBorderClass,
  } from './CardListItem.css.ts';
  import { CardListItemProps } from './CardListItem.types';
  import Tooltip from '../../Tooltip/Tooltip.vue';

  const props = withDefaults(defineProps<CardListItemProps>(), {
    statusChipVariant: ChipVariant.TERTIARY,
    borderColor: themeVars.colors.brand.primary.x5,
  });

  const cardWrapperClasses = computed(() => ({
    [cardWrapperClass]: true,
    [withSideBorderClass]: props.withSideBorder,
  }));
</script>

<template>
  <div
    :data-test-id="testId"
    :class="cardWrapperClasses"
    :style="{ borderColor: borderColor + ' !important' }"
  >
    <slot name="container">
      <div :class="cardContainerClass">
        <slot name="image">
          <Tooltip :label="imageTooltip">
            <div>
              <img v-if="image" :src="image" :class="cardImageWrapperClass" />
            </div>
          </Tooltip>
        </slot>

        <div :class="cardContentClass">
          <slot name="content">
            <slot name="title">
              <div :class="cardTitleClass">
                {{ title }}
                <Chip
                  :variant="statusChipVariant"
                  :class="statusChipClass"
                  squared
                  disable-uppercase
                  v-if="statusChip"
                >
                  {{ statusChip }}
                </Chip>
              </div>
            </slot>

            <div :class="cardDataWrapperClass">
              <div
                v-for="data in dataList"
                :key="data.value"
                :class="cardItemDataWrapperClass"
              >
                <div :class="cardItemDataLabelClass">{{ data.label }}</div>
                <div :class="cardItemDataValueClass">
                  {{ data.value || '-' }}
                </div>
              </div>
            </div>
          </slot>
        </div>

        <div :class="cardActionsWrapperClass">
          <slot name="actions">
            <Button
              v-for="button in actions"
              :key="button.label"
              :size="button.size || ButtonSizes.SMALL"
              :variant="button.variant || ButtonVariant.transparentOutlined"
              v-bind="button"
            />
          </slot>
        </div>
      </div>
    </slot>
    <slot name="extra-content" v-bind="props" />
  </div>
</template>
