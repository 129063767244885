import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const SIDEBAR_STORE_NAME = 'sidebar';

type SidebarState = {
  collapsed: boolean;
};

export const useSidebarStore = defineStore(SIDEBAR_STORE_NAME, {
  state: () =>
    useStorage<SidebarState>(SIDEBAR_STORE_NAME, {
      collapsed: false,
    }),
  actions: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
});
