export enum Environments {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

export const ENV_VARS = {
  environment: import.meta.env.VITE_ENVIRONMENT as Environments,
  node: import.meta.env.NODE_ENV,
  release: import.meta.env.VITE_RELEASE,
  faqs: import.meta.env.VITE_FAQS_URL,
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  },
  sentry: {
    dns: import.meta.env.VITE_SENTRY_DSN_HR,
  },
  serviceAgreements: {
    privacy: import.meta.env.VITE_PRIVACY_DOCUMENT_URL,
    terms: import.meta.env.VITE_TERMS_DOCUMENT_URL,
  },
  employees: {
    templateUrl: import.meta.env.VITE_IMPORT_USERS_TEMPLATE_URL,
  },
} as const;
