import { RouteRecordRaw } from 'vue-router';
import { authRoutesDictionary } from './auth-routes.dictionary';
import { useAuthStore } from '@tundr/auth';
import { appRoutesDictionary } from '../../../core/router/app-routes.dictionary.ts';

export const authRoutesConfig: RouteRecordRaw = {
  ...authRoutesDictionary.auth.root,
  redirect: { name: authRoutesDictionary.auth.login.name },
  children: [
    {
      ...authRoutesDictionary.auth.login,
      component: () => import('../pages/Login/LoginPage.vue'),
      beforeEnter: () => {
        const authStore = useAuthStore();
        if (authStore.isLogged)
          return { name: appRoutesDictionary.root.app.name };
      },
    },

    {
      ...authRoutesDictionary.auth.passwordRecovery,
      component: () =>
        import('../pages/PasswordRecovery/PasswordRecoveryPage.vue'),
    },
    {
      ...authRoutesDictionary.auth.register,
      component: () => import('../pages/Register/RegisterPage.vue'),
      props: true,
    },
  ],
};
