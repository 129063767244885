import { defineStore } from 'pinia';
import { AuthData, LoginCompany, LoginUser } from './auth-types.ts';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { computed } from 'vue';

export const AUTH_STORE_NAME = 'auth';

export const useAuthStore = defineStore(AUTH_STORE_NAME, () => {
  const authData = useStorage<AuthData | null>(
    AUTH_STORE_NAME,
    null,
    localStorage,
    { serializer: StorageSerializers.object },
  );
  const selectedCompany = useStorage<LoginCompany | null>(
    'selectedCompany',
    null,
    localStorage,
    { serializer: StorageSerializers.object },
  );

  const setCompanySelected = (company: LoginCompany | null) => {
    selectedCompany.value = company;
  };

  const setAuth = (userData: AuthData) => {
    authData.value = userData;
  };

  const logout = () => {
    authData.value = null;
    setCompanySelected(null);
  };

  const isLogged = computed<boolean>(() => {
    if (authData.value?.companies && authData.value?.companies.length > 0) {
      return !!selectedCompany.value;
    }
    return !!authData.value?.user;
  });

  const user = computed<LoginUser | undefined>(() => {
    return authData.value?.user;
  });

  const userCompanies = computed<LoginCompany[] | undefined>(() => {
    return authData.value?.companies;
  });

  return {
    isLogged,
    user,
    authData,
    selectedCompany,
    setCompanySelected,
    setAuth,
    logout,
    userCompanies,
  };
});
