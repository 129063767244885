<script setup lang="ts">
  import { sidebarSectionHeaderClass } from './SidebarSectionHeader.css';
  import { useSidebarStore } from '../../store/sidebar.store';

  export type SidebarSectionHeaderProps = {
    label: string;
  };

  const sidebarStore = useSidebarStore();

  defineProps<SidebarSectionHeaderProps>();
</script>

<template>
  <div v-if="!sidebarStore.collapsed" :class="sidebarSectionHeaderClass">
    {{ label }}
  </div>
</template>
