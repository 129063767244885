import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const authRoutesDictionary = moduleRouteDictionaryFactory(
  'auth',
  '/auth',
  {
    login: 'login',
    passwordRecovery: 'password-recovery',
    register: 'register/:code?',
  },
);
