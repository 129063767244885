import { creditsRoutesDictionary } from './credits-routes.dictionary';

export const creditsRoutesConfig = {
  ...creditsRoutesDictionary.credits.root,
  redirect: { name: creditsRoutesDictionary.credits.overview.name },
  children: [
    {
      ...creditsRoutesDictionary.credits.overview,
      component: () => import('../pages/CreditsOverview/CreditsOverview.vue'),
    },
    {
      ...creditsRoutesDictionary.credits.assign,
      component: () =>
        import('../pages/CreditsAssignment/CreditsAssignmentStepper.vue'),
    },
    {
      ...creditsRoutesDictionary.credits.detail,
      component: () => import('../pages/OrderDetail/OrderDetail.vue'),
      props: true,
    },
  ],
};
