import { useAuthStore } from '@tundr/auth';
import { useLocale } from '@tundr/i18n';
import { ToastVariant } from '@tundr/theme';
import { useToast } from '@tundr/toast';
import { useRouter } from 'vue-router';
import { appRoutesDictionary } from '../../core/router/app-routes.dictionary';
import { useStepper } from '@tundr/stepper';
import { LOGIN_STEPPER_NAMESPACE } from './../../modules/auth/pages/Login/login-stepper';
import { REGISTER_STEPPER_NAMESPACE } from './../../modules/auth/pages/Register/register-stepper';

export const useLogout = () => {
  const { t } = useLocale('common');
  const authStore = useAuthStore();
  const registerStepper = useStepper(REGISTER_STEPPER_NAMESPACE);
  const loginStepper = useStepper(LOGIN_STEPPER_NAMESPACE);

  const router = useRouter();
  const { show } = useToast();

  const onLogout = () => {
    registerStepper.destroyStepper();
    loginStepper.destroyStepper();
    authStore.logout();
    show({
      message: t('profile_menu.actions.logout.success.title'),
      description: t('profile_menu.actions.logout.success.description'),
      variant: ToastVariant.SUCCESS,
    });
    router.replace({ name: appRoutesDictionary.auth.login.name });
  };

  return {
    onLogout,
  };
};
