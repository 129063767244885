import { cardRoutesConfig } from './../../modules/app/card/routes/card-routes-config';
import { creditsRoutesConfig } from './../../modules/app/credits/routes/credits-routes.config';
import { RouteRecordRaw } from 'vue-router';
import { employeeRoutesConfig } from '../../modules/app/employee/routes/employee-routes.config.ts';
import AppRouter from '../../modules/app/AppRouter.vue';
import { NotFound } from '@tundr/ui-kit';
import { authRoutesConfig } from '../../modules/auth/routes/auth-routes.config';
import { dashboardRoutesConfig } from '../../modules/app/dashboard/routes/dashboard-routes.config';
import { officesRoutesConfig } from '../../modules/app/office/routes/office-routes-config';
import { appRoutesDictionary } from './app-routes.dictionary.ts';
import { hrRoutesConfig } from '../../modules/app/hr/routes/hr-routes.config.ts';
import { useAuthStore } from '@tundr/auth';

export const routes: RouteRecordRaw[] = [
  { ...appRoutesDictionary.root.notFound, component: NotFound },
  {
    ...appRoutesDictionary.root.root,
    redirect: appRoutesDictionary.auth.root,
  },
  authRoutesConfig,
  {
    ...appRoutesDictionary.root.app,
    component: AppRouter,
    children: [
      hrRoutesConfig,
      employeeRoutesConfig,
      officesRoutesConfig,
      creditsRoutesConfig,
      dashboardRoutesConfig,
      cardRoutesConfig,
    ],
    redirect: { name: appRoutesDictionary.employees.root.name },
    beforeEnter: () => {
      const authStore = useAuthStore();
      if (!authStore.isLogged)
        return { name: appRoutesDictionary.auth.login.name };
    },
  },
];
