import { ValidationMessageGenerator } from './types';
import { GENERIC_ERROR_RULE } from './config';

export function validationMessageGenerator(
  tFunc: (key: string, params: Record<string, unknown>) => string,
  i18nPath: string,
): ValidationMessageGenerator {
  return ({ field, rule }) => {
    const ruleName = rule?.name ?? GENERIC_ERROR_RULE;
    const ruleParams = (rule?.params as unknown[]) ?? [];
    const params = ruleParams.reduce<Record<string, unknown>>(
      (acc, param, index) => {
        acc[`${ruleName}${index}`] = param;
        return acc;
      },
      { field },
    );
    return tFunc(`${i18nPath}.${ruleName}`, params);
  };
}
